import * as Types from '../../../gql-global';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type DeleteAccountApiKeyMutationVariables = Types.Exact<{
  id: Types.Scalars['ID'];
}>;


export type DeleteAccountApiKeyMutation = { __typename: 'Mutations', deleteAccountApiKey?: { __typename: 'DeleteAccountApiKey', ok?: boolean | null, account?: { __typename: 'AccountType', id: number } | null } | null };


export const DeleteAccountApiKeyDocument = gql`
    mutation DeleteAccountApiKey($id: ID!) {
  deleteAccountApiKey(id: $id) {
    ok
    account {
      id
    }
  }
}
    `;
export type DeleteAccountApiKeyMutationFn = Apollo.MutationFunction<DeleteAccountApiKeyMutation, DeleteAccountApiKeyMutationVariables>;

/**
 * __useDeleteAccountApiKeyMutation__
 *
 * To run a mutation, you first call `useDeleteAccountApiKeyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteAccountApiKeyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteAccountApiKeyMutation, { data, loading, error }] = useDeleteAccountApiKeyMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteAccountApiKeyMutation(baseOptions?: Apollo.MutationHookOptions<DeleteAccountApiKeyMutation, DeleteAccountApiKeyMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteAccountApiKeyMutation, DeleteAccountApiKeyMutationVariables>(DeleteAccountApiKeyDocument, options);
      }
export type DeleteAccountApiKeyMutationHookResult = ReturnType<typeof useDeleteAccountApiKeyMutation>;
export type DeleteAccountApiKeyMutationResult = Apollo.MutationResult<DeleteAccountApiKeyMutation>;
export type DeleteAccountApiKeyMutationOptions = Apollo.BaseMutationOptions<DeleteAccountApiKeyMutation, DeleteAccountApiKeyMutationVariables>;