import * as Types from '../../../gql-global';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ApiTokensUserInfoQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type ApiTokensUserInfoQuery = { __typename: 'Query', whoami?: { __typename: 'UserType', id: number, account?: { __typename: 'AccountType', id: number, apiKeys?: Array<{ __typename: 'AccountApiKey', id: string, name: string, key: string, createdAt: Date }> | null } | null } | null };


export const ApiTokensUserInfoDocument = gql`
    query ApiTokensUserInfo {
  whoami {
    account {
      id
      apiKeys {
        id
        name
        key
        createdAt
      }
    }
    id
  }
}
    `;

/**
 * __useApiTokensUserInfoQuery__
 *
 * To run a query within a React component, call `useApiTokensUserInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useApiTokensUserInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useApiTokensUserInfoQuery({
 *   variables: {
 *   },
 * });
 */
export function useApiTokensUserInfoQuery(baseOptions?: Apollo.QueryHookOptions<ApiTokensUserInfoQuery, ApiTokensUserInfoQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ApiTokensUserInfoQuery, ApiTokensUserInfoQueryVariables>(ApiTokensUserInfoDocument, options);
      }
export function useApiTokensUserInfoLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ApiTokensUserInfoQuery, ApiTokensUserInfoQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ApiTokensUserInfoQuery, ApiTokensUserInfoQueryVariables>(ApiTokensUserInfoDocument, options);
        }
export type ApiTokensUserInfoQueryHookResult = ReturnType<typeof useApiTokensUserInfoQuery>;
export type ApiTokensUserInfoLazyQueryHookResult = ReturnType<typeof useApiTokensUserInfoLazyQuery>;
export type ApiTokensUserInfoQueryResult = Apollo.QueryResult<ApiTokensUserInfoQuery, ApiTokensUserInfoQueryVariables>;