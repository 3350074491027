import React, { useCallback } from 'react'
import {
  Box,
  Button,
  createStyles,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  InputAdornment,
  makeStyles,
  MenuItem,
  Theme,
} from '@material-ui/core'
import {
  IntegrationRow_ImpactIntegration_Fragment,
  IntegrationRow_ImpactSubaffiliateIntegration_Fragment,
  IntegrationRow_RakutenSubaffiliateIntegration_Fragment,
  IntegrationRow_RefersionIntegration_Fragment,
} from './operations/integration-row.generated'
import { Field, FieldArray, Form, Formik } from 'formik'
import { Select, TextField } from 'formik-material-ui'
import * as yup from 'yup'
import { IntegrationType } from '../gql-global'
import { isApolloError } from '@apollo/client'
import { ReactComponent as TrashIcon } from '../icons/trash_can.svg'
import useHasFeature from '../hooks/useHasFeature'

// Base schema shared by all integration types
const baseSchema = {
  name: yup.string().trim().required('Integration name is required.'),
  integrationType: yup.string().required('Integration type is required'),
}

const refersionSchema = yup.object().shape({
  ...baseSchema,
  key: yup.string().trim().required('Refersion key is required.'),
  igUsernameFields: yup
    .array()
    .of(yup.string().trim().required('username field cannot be empty'))
    .min(1)
    .ensure()
    .compact()
    .required('Username field id required.'),
  ttUsernameFields: yup.array().of(yup.string().trim().required('username field cannot be empty')).compact(),
})

const impactSchema = yup.object().shape({
  ...baseSchema,
  accountSid: yup.string().trim().required('Account SID is required.'),
  accessToken: yup.string().trim().required('Access Token is required.'),
  campaignId: yup.string().trim().required('Campaign ID is required.'),
  linkTextMessage: yup.string().trim().required('Link Text Message is required.'),
  campaignRedirectDomain: yup
    .string()
    .trim()
    .url('Must be a valid URL')
    .required('Campaign Redirect Domain is required.'),
})

const impactSubaffiliateSchema = yup.object().shape({
  ...baseSchema,
  campaignId: yup.string().trim().required('Campaign ID is required.'),
})

const rakutenSubaffiliateSchema = yup.object().shape({
  ...baseSchema,
  advertiserId: yup.string().trim().required('Advertiser ID is required.'),
})

// Type definitions for each integration
interface BaseIntegrationFields {
  name: string
  integrationType: IntegrationType
}

interface RefersionFields extends BaseIntegrationFields {
  key: string
  igUsernameFields: string[]
  ttUsernameFields: string[]
}

interface ImpactFields extends BaseIntegrationFields {
  accountSid: string
  accessToken: string
  campaignId: string
  linkTextMessage: string
  campaignRedirectDomain: string
}

interface ImpactSubaffiliateFields extends BaseIntegrationFields {
  campaignId: string
}

interface RakutenSubaffiliateFields extends BaseIntegrationFields {
  advertiserId: string
}

export type AddEditCustomerIntegrationFormFields =
  | RefersionFields
  | ImpactFields
  | ImpactSubaffiliateFields
  | RakutenSubaffiliateFields

interface AddEditCustomerIntegrationProps {
  open: boolean
  onCancel(): void
  onSubmit(values: AddEditCustomerIntegrationFormFields): Promise<void>
  existing?:
    | IntegrationRow_RefersionIntegration_Fragment
    | IntegrationRow_ImpactIntegration_Fragment
    | IntegrationRow_ImpactSubaffiliateIntegration_Fragment
    | IntegrationRow_RakutenSubaffiliateIntegration_Fragment
  error?: Error | null
  isSubaffiliateSection?: boolean
}

const platformNameMap: Record<
  | IntegrationType.Refersion
  | IntegrationType.Impact
  | IntegrationType.ImpactSubaffiliate
  | IntegrationType.RakutenSubaffiliate,
  string
> = {
  [IntegrationType.Refersion]: 'Refersion',
  [IntegrationType.Impact]: 'Impact',
  [IntegrationType.ImpactSubaffiliate]: 'Impact Subaffiliate',
  [IntegrationType.RakutenSubaffiliate]: 'Rakuten Subaffiliate',
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    field: {
      marginBottom: theme.spacing(5),
    },
    select: {
      width: '100%',
    },
    igUsernameField: {
      width: '100%',
    },
  }),
)

function AddEditCustomerIntegration({
  open,
  onCancel,
  existing,
  onSubmit,
  isSubaffiliateSection,
  error,
}: AddEditCustomerIntegrationProps): React.ReactElement {
  const styles = useStyles()
  const initialType = existing?.integrationType
    ? existing?.integrationType
    : isSubaffiliateSection
    ? IntegrationType.ImpactSubaffiliate
    : IntegrationType.Refersion

  const { hasFeature: hasRakuten } = useHasFeature('hasRakuten')

  const getInitialValues = useCallback(
    (type: IntegrationType) => {
      if (existing) return existing

      const base = { name: '', integrationType: type }

      switch (type) {
        case IntegrationType.Refersion:
          return { ...base, key: '', igUsernameFields: [''], ttUsernameFields: [''] }
        case IntegrationType.Impact:
          return {
            ...base,
            accountSid: '',
            accessToken: '',
            campaignId: '',
            linkTextMessage: '',
            campaignRedirectDomain: '',
          }
        case IntegrationType.ImpactSubaffiliate:
          return { ...base, campaignId: '' }
        case IntegrationType.RakutenSubaffiliate:
          return { ...base, advertiserId: '' }
        default:
          console.error('Invalid integration type')
          throw new Error('Invalid integration type')
      }
    },
    [existing],
  )

  const sanitizeValues = useCallback(
    (values: Readonly<AddEditCustomerIntegrationFormFields>): AddEditCustomerIntegrationFormFields => {
      const baseValues = { ...values, name: values.name.trim() }

      switch (values.integrationType) {
        case IntegrationType.Refersion:
          return {
            ...baseValues,
            key: (values as RefersionFields).key.trim(),
            igUsernameFields: (values as RefersionFields).igUsernameFields.map(f => f.trim()).filter(f => !!f),
            ttUsernameFields: (values as RefersionFields).ttUsernameFields.map(f => f.trim()).filter(f => !!f),
          }
        case IntegrationType.Impact:
          return {
            ...baseValues,
            accountSid: (values as ImpactFields).accountSid.trim(),
            accessToken: (values as ImpactFields).accessToken.trim(),
            campaignId: (values as ImpactFields).campaignId.trim(),
            linkTextMessage: (values as ImpactFields).linkTextMessage.trim(),
            campaignRedirectDomain: (values as ImpactFields).campaignRedirectDomain.trim(),
          }
        case IntegrationType.ImpactSubaffiliate:
          const campaignId = (values as ImpactSubaffiliateFields).campaignId.trim()
          return { ...baseValues, campaignId }
        case IntegrationType.RakutenSubaffiliate:
          const advertiserId = (values as RakutenSubaffiliateFields).advertiserId.trim()
          return { ...baseValues, advertiserId }
        default:
          console.error('Invalid integration type')
          throw new Error('Invalid integration type')
      }
    },
    [],
  )

  const errorMessage =
    (error &&
      (isApolloError(error) && error.graphQLErrors.some(e => e.extensions?.code === 'DUPLICATE_KEY')
        ? 'CRM Integration with that name already exists'
        : `Error ${!!existing ? 'editing' : 'creating'} this CRM Integration`)) ||
    ''

  async function handleSubmit(values: AddEditCustomerIntegrationFormFields): Promise<void> {
    await onSubmit(sanitizeValues(values))
  }

  return (
    <Dialog open={open} onClose={onCancel}>
      <Formik
        initialValues={getInitialValues(initialType)}
        enableReinitialize
        validate={values => {
          try {
            const validationSchemas = {
              [IntegrationType.Refersion]: refersionSchema,
              [IntegrationType.Impact]: impactSchema,
              [IntegrationType.ImpactSubaffiliate]: impactSubaffiliateSchema,
              [IntegrationType.RakutenSubaffiliate]: rakutenSubaffiliateSchema,
            }
            const schema = validationSchemas[values.integrationType as keyof typeof validationSchemas]
            schema.validateSync(values, { abortEarly: false })
            return {}
          } catch (err) {
            if (err instanceof yup.ValidationError) {
              const errors: Record<string, string> = {}
              err.inner.forEach(e => {
                if (e.path) {
                  errors[e.path] = e.message
                }
              })
              return errors
            }
            return {}
          }
        }}
        onSubmit={handleSubmit}
      >
        {({ values, setValues }) => (
          <Form noValidate>
            <DialogTitle>{!!existing ? 'Edit' : 'Add'} Integration</DialogTitle>
            <DialogContent>
              <Box display="flex" flexDirection="column" minWidth={300}>
                <Field
                  className={`${styles.field} ${styles.select}`}
                  component={Select}
                  label="Platform"
                  name="integrationType"
                  disabled={!!existing}
                  onChange={(e: React.ChangeEvent<{ value: unknown }>) => {
                    const newType = e.target.value as IntegrationType
                    setValues(getInitialValues(newType), true)
                  }}
                >
                  {!isSubaffiliateSection && (
                    <MenuItem value={IntegrationType.Refersion}>{platformNameMap[IntegrationType.Refersion]}</MenuItem>
                  )}
                  {!isSubaffiliateSection && (
                    <MenuItem value={IntegrationType.Impact}>{platformNameMap[IntegrationType.Impact]}</MenuItem>
                  )}
                  {isSubaffiliateSection && (
                    <MenuItem value={IntegrationType.ImpactSubaffiliate}>
                      {platformNameMap[IntegrationType.ImpactSubaffiliate]}
                    </MenuItem>
                  )}
                  {isSubaffiliateSection && hasRakuten && (
                    <MenuItem value={IntegrationType.RakutenSubaffiliate}>
                      {platformNameMap[IntegrationType.RakutenSubaffiliate]}
                    </MenuItem>
                  )}
                </Field>
                <Field className={styles.field} component={TextField} label="Name" name="name" />

                {values.integrationType === IntegrationType.Refersion ? (
                  <>
                    <Field className={styles.field} component={TextField} label="API Key" name="key" />
                    <FieldArray
                      name="igUsernameFields"
                      render={({ form, remove, push }) => (
                        <Box mb={5}>
                          {form.values.igUsernameFields?.map((_: unknown, i: number) => (
                            <Box mb={2} key={i}>
                              <Field
                                className={styles.igUsernameField}
                                component={TextField}
                                label="Instagram Username Field ID"
                                name={`igUsernameFields[${i}]`}
                                InputProps={{
                                  endAdornment:
                                    i > 0 ? (
                                      <InputAdornment position="end">
                                        <IconButton onClick={() => remove(i)} edge="end">
                                          <TrashIcon />
                                        </IconButton>
                                      </InputAdornment>
                                    ) : undefined,
                                }}
                              />
                            </Box>
                          ))}
                          <Button onClick={() => push('')}>Add Username Field</Button>
                        </Box>
                      )}
                    />
                    <FieldArray
                      name="ttUsernameFields"
                      render={({ form, remove, push }) => (
                        <Box mb={5}>
                          {form.values.ttUsernameFields?.map((_: unknown, i: number) => (
                            <Box mb={2} key={i}>
                              <Field
                                className={styles.igUsernameField}
                                component={TextField}
                                label="TikTok Username Field ID"
                                name={`ttUsernameFields[${i}]`}
                                InputProps={{
                                  endAdornment:
                                    i > 0 ? (
                                      <InputAdornment position="end">
                                        <IconButton onClick={() => remove(i)} edge="end">
                                          <TrashIcon />
                                        </IconButton>
                                      </InputAdornment>
                                    ) : undefined,
                                }}
                              />
                            </Box>
                          ))}
                          <Button onClick={() => push('')}>Add TikTok Username Field</Button>
                        </Box>
                      )}
                    />
                  </>
                ) : (
                  <>
                    {values.integrationType === IntegrationType.Impact && (
                      <>
                        <Field className={styles.field} component={TextField} label="Account SID" name="accountSid" />
                        <Field className={styles.field} component={TextField} label="Access Token" name="accessToken" />
                        <Field
                          className={styles.field}
                          component={TextField}
                          label="Campaign Redirect Domain"
                          name="campaignRedirectDomain"
                        />
                        <Field
                          className={styles.field}
                          component={TextField}
                          label="Link Text Message"
                          name="linkTextMessage"
                        />
                      </>
                    )}
                    {[IntegrationType.ImpactSubaffiliate, IntegrationType.Impact].includes(values.integrationType) && (
                      <Field className={styles.field} component={TextField} label="Campaign ID" name="campaignId" />
                    )}
                    {values.integrationType === IntegrationType.RakutenSubaffiliate && (
                      <Field className={styles.field} component={TextField} label="Advertiser ID" name="advertiserId" />
                    )}
                  </>
                )}
              </Box>
              {errorMessage}
            </DialogContent>
            <DialogActions>
              <Button onClick={onCancel} color="secondary">
                Cancel
              </Button>
              <Button type="submit">Save</Button>
            </DialogActions>
          </Form>
        )}
      </Formik>
    </Dialog>
  )
}

export default AddEditCustomerIntegration
