import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Box, Drawer, List } from '@material-ui/core'

import { ReactComponent as AnalyticsIcon } from '../../icons/analytics_major_monotone.svg'
import { ReactComponent as ReviewIcon } from '../../icons/review.svg'
import { ReactComponent as VariantIcon } from '../../icons/variant_major_monotone.svg'
import { ReactComponent as SettingsIcon } from '../../icons/settings_major_monotone.svg'
import { ReactComponent as IntegrationsIcon } from '../../icons/integrations.svg'
import { ReactComponent as CustomerIcon } from '../../icons/people-outlined-cropped.svg'
import { ReactComponent as PartyIcon } from '../../icons/confetti_major_monotone.svg'
import { ReactComponent as MessageIcon } from '../../icons/message_icon_major.svg'
import { ReactComponent as TrophyIcon } from '../../icons/trophy.svg'
import { primary } from '../../loudcrowd-theme'
import { DASHBOARD_ROUTE } from '../../dashboard/routes'
import { REVIEW_ROUTE } from '../../review/routes'
import { CONTENT_ROUTE } from '../../content/routes'
import { CUSTOMER_ROUTE } from '../../customer/routes'
import { CAMPAIGN_ROUTE } from '../../campaign/routes'
import { MESSAGE_ROUTE } from '../../message/routes'
import { CHALLENGES_ROUTE } from '../../challenges/routes'
import NavItem from './NavItem'
import SocialAccountPicker from './SocialAccountPicker'
import {
  API_KEY_MANAGEMENT_ROUTE,
  BILLING_MANAGEMENT_ROUTE,
  LABEL_MANAGEMENT_ROUTE,
  REWARD_MANAGEMENT_ROUTE,
  SEGMENT_MANAGEMENT_ROUTE,
  USER_MANAGEMENT_ROUTE,
} from '../../settings/routes'
import { INTEGRATIONS_ROUTE } from '../../integrations/routes'
import { AccountProductName } from '../../gql-global'
import logo from '../../images/logo.png'
import { Route } from '../../types/route'

const drawerWidth = 260

const useStyles = makeStyles({
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    backgroundColor: primary[800],
    width: drawerWidth,
    display: 'flex',
    flexDirection: 'column',
  },
  items: {
    paddingRight: '0',
    paddingLeft: 16,
    marginBottom: 4,
  },
  topBlock: {
    flexGrow: 1,
    paddingTop: 0,
  },
})

type NavItemType = {
  route: Route
  label: string
  icon: React.ReactElement | null
  allowFbTester: boolean
  requiresProducts: boolean
  dataIntercomTarget?: string
}

const navItems: NavItemType[] = [
  {
    route: DASHBOARD_ROUTE,
    label: 'Dashboard',
    icon: <AnalyticsIcon fill="currentColor" height="20px" width="20px" />,
    allowFbTester: true,
    requiresProducts: true,
  },
  {
    route: MESSAGE_ROUTE,
    label: 'Messages',
    icon: <MessageIcon fill="currentColor" height="20px" width="20px" />,
    allowFbTester: true,
    requiresProducts: true,
  },
  {
    route: CONTENT_ROUTE,
    label: 'Content',
    icon: <VariantIcon fill="currentColor" height="20px" width="20px" />,
    allowFbTester: true,
    requiresProducts: true,
    dataIntercomTarget: 'Content Nav',
  },
  {
    route: REVIEW_ROUTE,
    label: 'Review',
    icon: <ReviewIcon fill="currentColor" height="20px" width="20" />,
    allowFbTester: false,
    requiresProducts: true,
  },
  {
    route: CAMPAIGN_ROUTE,
    label: 'Programs',
    icon: <PartyIcon fill="currentColor" height="20px" width="20px" />,
    allowFbTester: false,
    requiresProducts: true,
  },
  {
    route: CHALLENGES_ROUTE,
    label: 'Challenges',
    icon: <TrophyIcon fill="currentColor" height="20px" width="20px" />,
    allowFbTester: false,
    requiresProducts: true,
  },
  {
    route: CUSTOMER_ROUTE,
    label: 'Customers',
    icon: <CustomerIcon fill="currentColor" height="24px" width="24" />,
    allowFbTester: true,
    requiresProducts: true,
  },
]

const bottomNavItems: NavItemType[] = [
  {
    label: 'Integrations',
    icon: <IntegrationsIcon fill="currentColor" height="20px" width="20px" />,
    route: INTEGRATIONS_ROUTE,
    requiresProducts: true,
    allowFbTester: true,
  },
]

const settingsNavItems: NavItemType[] = [
  {
    label: 'Billing',
    icon: null,
    route: BILLING_MANAGEMENT_ROUTE,
    requiresProducts: false,
    allowFbTester: false,
  },
  {
    label: 'Users',
    icon: null,
    route: USER_MANAGEMENT_ROUTE,
    requiresProducts: false,
    allowFbTester: false,
  },
  {
    label: 'Labels',
    icon: null,
    route: LABEL_MANAGEMENT_ROUTE,
    requiresProducts: true,
    allowFbTester: false,
  },
  {
    label: 'Segments',
    icon: null,
    route: SEGMENT_MANAGEMENT_ROUTE,
    requiresProducts: true,
    allowFbTester: false,
  },
  {
    label: 'Rewards',
    icon: null,
    route: REWARD_MANAGEMENT_ROUTE,
    requiresProducts: true,
    allowFbTester: false,
  },
  {
    label: 'API Keys',
    icon: null,
    route: API_KEY_MANAGEMENT_ROUTE,
    requiresProducts: false,
    allowFbTester: false,
  },
]

interface NavSidebarProps {
  roles?: string[]
  products?: AccountProductName[]
  loading: boolean
  username?: string
  noPicker?: boolean
  isFbTester?: boolean
  accountId?: number | null
}

const NavSidebar: React.FC<NavSidebarProps> = ({
  loading,
  roles = [],
  products = [],
  username = '',
  noPicker = false,
  isFbTester = false,
  accountId = null,
}) => {
  const classes = useStyles()
  const shownNavItems = navItems.filter(
    item => (!isFbTester || item.allowFbTester) && item.route.hasAccess(roles, products, username, accountId),
  )
  const shownBottomNavItems = bottomNavItems.filter(
    item => item.route && item.route.hasAccess(roles, products, username, accountId),
  )
  const shownSettingsNavItems = isFbTester
    ? []
    : settingsNavItems.filter(item => item.route && item.route.hasAccess(roles, products, username, accountId))

  return (
    <Drawer className={classes.drawer} variant="permanent" anchor="left" classes={{ paper: classes.drawerPaper }}>
      {!loading && !noPicker && <SocialAccountPicker />}
      <List className={classes.topBlock}>
        {!loading &&
          shownNavItems.map(item => {
            const label = item.label === 'Customers' ? 'Social CRM' : item.label
            return (
              <Box key={item.route.path} className={classes.items}>
                <NavItem to={item.route.path} primary={label} icon={item.icon || undefined} />
              </Box>
            )
          })}
        {loading && (
          <>
            <Box className={classes.items}>
              <NavItem loading />
            </Box>
            <Box className={classes.items}>
              <NavItem loading />
            </Box>
          </>
        )}
      </List>
      <List>
        {!loading &&
          shownBottomNavItems.map(item => (
            <Box key={item.route.path} className={classes.items}>
              <NavItem to={item.route.path} primary={item.label} icon={item.icon || undefined} />
            </Box>
          ))}
        {!!shownSettingsNavItems.length && (
          <Box key={'/Settings'} className={classes.items}>
            <NavItem primary={'Settings'} icon={<SettingsIcon height="20px" width="20px" />}>
              {shownSettingsNavItems.map(item => {
                return (
                  <Box key={item.route.path} className={classes.items}>
                    <NavItem to={item.route.path} primary={item.label} />
                  </Box>
                )
              })}
            </NavItem>
          </Box>
        )}
      </List>
      <Box margin={8} marginTop={5}>
        <img width="100%" src={logo} alt="LoudCrowd Logo" />
      </Box>
    </Drawer>
  )
}

export default NavSidebar
