import React, { useRef, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import useTitle from '../../utils/use-title'
import Page from '../../Page'
import {
  Box,
  Typography,
  Button,
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  IconButton,
  Menu,
  MenuItem,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  CircularProgress,
  Tooltip,
} from '@material-ui/core'
import { ReactComponent as PlusIcon } from '../../icons/plus_minor.svg'
import { ReactComponent as KebabIcon } from '../../icons/kebab.svg'
import { AccountApiTokensDocument, useAccountApiTokensQuery } from './operations/account-api-tokens.generated'
import { ApiTokensUserInfoQuery, useApiTokensUserInfoQuery } from './operations/api-tokens-user-info.generated'
import { AccountApiKey } from '../../gql-global'
import { Skeleton } from '@material-ui/lab'
import { useCreateAccountApiKeyMutation } from './operations/create-api-token.generated'
import { useDeleteAccountApiKeyMutation } from './operations/delete-api-token.generated'
import { useToast } from '../../components/Alert/ToastProvider'
import { formatDistanceToNow, format } from 'date-fns'

type AccountApiKeyType = Omit<AccountApiKey, 'account'> & {
  account?: {
    id: number
  } | null
}

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(2),
  },
  title: {
    fontSize: '1.5rem',
    fontWeight: 'bold',
  },
  description: {
    marginTop: theme.spacing(1),
  },

  firstHeader: {
    paddingLeft: theme.spacing(12),
  },
  addTokenDialog: {
    margin: 'auto',
    width: 600,
  },
  nameInput: {
    display: 'flex',
    marginBottom: theme.spacing(5),
    minWidth: 300,
  },
  warningBox: {
    backgroundColor: theme.palette.warning.light,
    color: theme.palette.warning.dark,
  },
  keyDisplay: {
    whiteSpace: 'normal',
    width: '70%',
    wordWrap: 'break-word',
  },
}))

interface ApiTokenRowProps {
  apiToken: AccountApiKeyType
  onRemoveApiToken: (arg0: AccountApiKeyType) => void
}

const ApiTokenRow: React.FC<ApiTokenRowProps> = ({ apiToken, onRemoveApiToken }) => {
  const kebabButtonRef = useRef<HTMLButtonElement>(null)
  const [actionMenuOpen, setActionMenuOpen] = useState(false)

  return (
    <TableRow key={apiToken.id}>
      <TableCell>{apiToken.name}</TableCell>
      <TableCell>
        <Tooltip title={format(apiToken.createdAt, 'PPp')} placement="top-start">
          <Typography variant="caption">
            {formatDistanceToNow(apiToken.createdAt, { addSuffix: true }).replace('about ', '')}
          </Typography>
        </Tooltip>
      </TableCell>
      <TableCell>{apiToken.key}</TableCell>
      <TableCell>
        <IconButton ref={kebabButtonRef} onClick={() => setActionMenuOpen(true)} color="primary">
          <KebabIcon width={20} height={20} />
        </IconButton>
        <Menu
          open={actionMenuOpen}
          anchorEl={kebabButtonRef.current}
          onClose={() => setActionMenuOpen(false)}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
          transformOrigin={{ vertical: 'top', horizontal: 'right' }}
          getContentAnchorEl={null}
        >
          <Box>
            <MenuItem
              onClick={() => {
                setActionMenuOpen(false)
                onRemoveApiToken(apiToken)
              }}
            >
              <Typography variant="subtitle1" color="textSecondary">
                Remove Token
              </Typography>
            </MenuItem>
          </Box>
        </Menu>
      </TableCell>
    </TableRow>
  )
}

const LoadingApiTokenRow: React.FC = () => {
  return (
    <TableRow>
      <TableCell>
        <Skeleton />
      </TableCell>
      <TableCell>
        <Skeleton />
      </TableCell>
      <TableCell>
        <Skeleton />
      </TableCell>
      <TableCell>
        <Skeleton />
      </TableCell>
    </TableRow>
  )
}

const ApiTokenManagment: React.FC = () => {
  useTitle('API Token - Settings')
  const styles = useStyles()
  const { showToast } = useToast()

  const [selectedAccountId, setSelectedAccountId] = useState<number | null>(null)

  const [addApiTokenDialogOpen, setAddApiTokenDialogOpen] = useState(false)
  const [newApiTokenName, setNewApiTokenName] = useState<string | null>(null)
  const [createTokenLoading, setCreateTokenLoading] = useState(false)
  const [newlyCreatedToken, setNewlyCreatedToken] = useState<string | null>(null)
  const [viewTokenDialogOpen, setViewTokenDialogOpen] = useState(false)

  useApiTokensUserInfoQuery({
    onCompleted: (data: ApiTokensUserInfoQuery) => {
      if (!selectedAccountId && data?.whoami?.account) setSelectedAccountId(data.whoami.account.id)
    },
  })

  const { loading, data } = useAccountApiTokensQuery({
    skip: !selectedAccountId,
    variables: {
      accountId: selectedAccountId?.toString() || '',
    },
  })

  const accountApiTokens = data?.account?.apiKeys || []

  const [createAccountApiKey] = useCreateAccountApiKeyMutation({
    update: (cache, { data }) => {
      const account = data?.createAccountApiKey?.accountApiKey?.account
      const newAccountApiKey = data?.createAccountApiKey?.accountApiKey
      if (account && newAccountApiKey) {
        cache.modify({
          id: cache.identify(account),
          fields: {
            accountApiKeys(existingAccountApiKeys) {
              return {
                ...existingAccountApiKeys,
                results: [...existingAccountApiKeys.results, newAccountApiKey],
              }
            },
          },
        })
      }
    },
    onError: e => {
      showToast({
        title: 'Error: Creating Api Token',
        message: 'Something went wrong when creating this api token, please try again. ' + e.message,
        severity: 'error',
      })
    },
    refetchQueries: [
      {
        query: AccountApiTokensDocument,
        variables: {
          accountId: selectedAccountId?.toString() || '',
        },
      },
    ],
  })

  const [deleteAccountApiKey] = useDeleteAccountApiKeyMutation({
    update: (cache, { data }) => {
      const account = data?.deleteAccountApiKey?.account
      if (account && data?.deleteAccountApiKey?.ok) {
        cache.modify({
          id: cache.identify(account),
          fields: {
            account_api_keys(_, { DELETE }) {
              return DELETE
            },
          },
        })
      }
    },
    onError: e => {
      showToast({
        title: 'Error: Deleting API Token',
        message: e.message,
        severity: 'error',
      })
    },
    refetchQueries: [
      {
        query: AccountApiTokensDocument,
        variables: {
          accountId: selectedAccountId?.toString() || '',
        },
      },
    ],
  })

  function onCloseAddTokenDialog() {
    setNewApiTokenName(null)
    setAddApiTokenDialogOpen(false)
  }

  async function onCreateToken(name: string) {
    setCreateTokenLoading(true)

    const results = await createAccountApiKey({
      variables: {
        name: name,
        accountId: selectedAccountId?.toString() || '',
      },
    })

    setCreateTokenLoading(false)
    if (!!results.data?.createAccountApiKey?.ok) {
      onCloseAddTokenDialog()
      setNewlyCreatedToken(results.data?.createAccountApiKey?.oneTimeViewKey || null)
      setViewTokenDialogOpen(true)
    }
  }

  return (
    <Page>
      <Box px={12} py={10}>
        <Box display="flex" justifyContent="space-between" flex={1}>
          <Typography variant="h5" className={styles.title}>
            API Token Management
          </Typography>
          <Box display="flex">
            <Button
              variant="outlined"
              color="primary"
              onClick={() => {
                setAddApiTokenDialogOpen(true)
              }}
              startIcon={<PlusIcon height={16} width={16} />}
            >
              Create API Token
            </Button>
          </Box>
        </Box>
        <Box mt={8}>
          <TableContainer component={Paper}>
            <Table style={{ tableLayout: 'auto' }}>
              <TableHead>
                <TableRow>
                  <TableCell className={styles.firstHeader}>
                    <Typography variant="subtitle2" color="textPrimary">
                      Name
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography variant="subtitle2" color="textPrimary">
                      Created
                    </Typography>
                  </TableCell>
                  <TableCell className={styles.firstHeader}>
                    <Typography variant="subtitle2" color="textPrimary">
                      Token
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography variant="subtitle2" color="textPrimary">
                      Actions
                    </Typography>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {!loading &&
                  accountApiTokens?.map(
                    (apiToken: AccountApiKey): JSX.Element => (
                      <ApiTokenRow
                        key={apiToken.id}
                        apiToken={apiToken}
                        onRemoveApiToken={(t: AccountApiKeyType) => {
                          // TODO Implement the logic to remove the API token
                          deleteAccountApiKey({
                            variables: {
                              id: t.id,
                            },
                          })
                        }}
                      />
                    ),
                  )}
                {!!loading && <LoadingApiTokenRow />}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      </Box>
      <Dialog
        className={styles.addTokenDialog}
        fullWidth
        open={addApiTokenDialogOpen}
        onClose={() => onCloseAddTokenDialog()}
      >
        <form
          onSubmit={e => {
            e.preventDefault()
            if (!!newApiTokenName) {
              onCreateToken(newApiTokenName)
            }
          }}
        >
          <DialogTitle>Create API Token</DialogTitle>
          <DialogContent>
            <Box component="form" display="flex" flexDirection="column">
              <TextField
                label="Name"
                type="name"
                placeholder="Enter a token name"
                helperText="Choose a descriptive label for the token"
                fullWidth
                value={newApiTokenName}
                onChange={e => setNewApiTokenName(e.target.value)}
                className={styles.nameInput}
              />
            </Box>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => onCloseAddTokenDialog()}>Cancel</Button>
            <Button
              type="submit"
              disabled={!newApiTokenName || newApiTokenName === ''}
              variant="contained"
              color="primary"
            >
              {createTokenLoading ? <CircularProgress color="secondary" size={24} /> : 'Create Token'}
            </Button>
          </DialogActions>
        </form>
      </Dialog>
      <Dialog
        className={styles.addTokenDialog}
        fullWidth
        open={viewTokenDialogOpen}
        onClose={() => onCloseAddTokenDialog()}
      >
        <DialogTitle>Copy New API Token</DialogTitle>
        <DialogContent>
          <Box p={4} className={styles.warningBox} borderRadius={4} my={2}>
            Here's your new API token. Please copy it and store it in a safe place. You won't be able to see it again.
          </Box>
          <Box p={4} borderRadius={4} my={2} display="flex" alignItems="center" justifyContent={'center'}>
            <Box mr={5} className={styles.keyDisplay}>
              {newlyCreatedToken}
            </Box>
            <Button
              variant="contained"
              color="primary"
              onClick={() => {
                if (newlyCreatedToken) {
                  navigator.clipboard.writeText(newlyCreatedToken)
                }
              }}
            >
              Copy Token
            </Button>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setViewTokenDialogOpen(false)}>Close</Button>
        </DialogActions>
      </Dialog>
    </Page>
  )
}

export default ApiTokenManagment
