import { AccountProductName } from '../gql-global'
import { Route } from '../types/route'

export const SETTINGS_ROUTE = new Route('/settings', undefined, undefined, undefined)
export const USER_MANAGEMENT_ROUTE = SETTINGS_ROUTE.subroute('/users', ['OWNER'], undefined, undefined)
export const LABEL_MANAGEMENT_ROUTE = SETTINGS_ROUTE.subroute('/labels', undefined, undefined, AccountProductName.Base)
export const SEGMENT_MANAGEMENT_ROUTE = SETTINGS_ROUTE.subroute(
  '/segments',
  undefined,
  undefined,
  AccountProductName.Base,
)
export const REWARD_MANAGEMENT_ROUTE = SETTINGS_ROUTE.subroute(
  '/rewards',
  ['OWNER'],
  undefined,
  AccountProductName.Base,
)
export const BILLING_MANAGEMENT_ROUTE = SETTINGS_ROUTE.subroute('/billing', ['OWNER'])
export const BILLING_CALLBACK_ROUTE = BILLING_MANAGEMENT_ROUTE.subroute('/success', ['OWNER'])

export const API_KEY_MANAGEMENT_ROUTE = SETTINGS_ROUTE.subroute(
  '/api-keys',
  ['OWNER'],
  'storeApiKeys',
  AccountProductName.Base,
)
