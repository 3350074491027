import * as Types from '../../../gql-global';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type AccountApiTokensQueryVariables = Types.Exact<{
  accountId: Types.Scalars['ID'];
}>;


export type AccountApiTokensQuery = { __typename: 'Query', account?: { __typename: 'AccountType', id: number, apiKeys?: Array<{ __typename: 'AccountApiKey', id: string, name: string, key: string, createdAt: Date }> | null } | null };


export const AccountApiTokensDocument = gql`
    query AccountApiTokens($accountId: ID!) {
  account(id: $accountId) {
    id
    apiKeys {
      id
      name
      key
      createdAt
    }
  }
}
    `;

/**
 * __useAccountApiTokensQuery__
 *
 * To run a query within a React component, call `useAccountApiTokensQuery` and pass it any options that fit your needs.
 * When your component renders, `useAccountApiTokensQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAccountApiTokensQuery({
 *   variables: {
 *      accountId: // value for 'accountId'
 *   },
 * });
 */
export function useAccountApiTokensQuery(baseOptions: Apollo.QueryHookOptions<AccountApiTokensQuery, AccountApiTokensQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AccountApiTokensQuery, AccountApiTokensQueryVariables>(AccountApiTokensDocument, options);
      }
export function useAccountApiTokensLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AccountApiTokensQuery, AccountApiTokensQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AccountApiTokensQuery, AccountApiTokensQueryVariables>(AccountApiTokensDocument, options);
        }
export type AccountApiTokensQueryHookResult = ReturnType<typeof useAccountApiTokensQuery>;
export type AccountApiTokensLazyQueryHookResult = ReturnType<typeof useAccountApiTokensLazyQuery>;
export type AccountApiTokensQueryResult = Apollo.QueryResult<AccountApiTokensQuery, AccountApiTokensQueryVariables>;