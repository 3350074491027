import * as Types from '../../../gql-global';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CreateAccountApiKeyMutationVariables = Types.Exact<{
  accountId: Types.Scalars['ID'];
  name: Types.Scalars['String'];
}>;


export type CreateAccountApiKeyMutation = { __typename: 'Mutations', createAccountApiKey?: { __typename: 'CreateAccountApiKey', ok?: boolean | null, oneTimeViewKey?: string | null, accountApiKey?: { __typename: 'AccountApiKey', id: string, name: string, key: string, createdAt: Date, account?: { __typename: 'AccountType', id: number } | null } | null } | null };


export const CreateAccountApiKeyDocument = gql`
    mutation CreateAccountApiKey($accountId: ID!, $name: String!) {
  createAccountApiKey(accountId: $accountId, name: $name) {
    ok
    oneTimeViewKey
    accountApiKey {
      id
      name
      key
      createdAt
      account {
        id
      }
    }
  }
}
    `;
export type CreateAccountApiKeyMutationFn = Apollo.MutationFunction<CreateAccountApiKeyMutation, CreateAccountApiKeyMutationVariables>;

/**
 * __useCreateAccountApiKeyMutation__
 *
 * To run a mutation, you first call `useCreateAccountApiKeyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateAccountApiKeyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createAccountApiKeyMutation, { data, loading, error }] = useCreateAccountApiKeyMutation({
 *   variables: {
 *      accountId: // value for 'accountId'
 *      name: // value for 'name'
 *   },
 * });
 */
export function useCreateAccountApiKeyMutation(baseOptions?: Apollo.MutationHookOptions<CreateAccountApiKeyMutation, CreateAccountApiKeyMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateAccountApiKeyMutation, CreateAccountApiKeyMutationVariables>(CreateAccountApiKeyDocument, options);
      }
export type CreateAccountApiKeyMutationHookResult = ReturnType<typeof useCreateAccountApiKeyMutation>;
export type CreateAccountApiKeyMutationResult = Apollo.MutationResult<CreateAccountApiKeyMutation>;
export type CreateAccountApiKeyMutationOptions = Apollo.BaseMutationOptions<CreateAccountApiKeyMutation, CreateAccountApiKeyMutationVariables>;