import React, { useState } from 'react'
import { Box, Button } from '@material-ui/core'
import {
  IntegrationRow_RefersionIntegration_Fragment,
  IntegrationRow_ImpactIntegration_Fragment,
  IntegrationRow_ImpactSubaffiliateIntegration_Fragment,
  IntegrationRow_RakutenSubaffiliateIntegration_Fragment,
  IntegrationRowFragmentDoc,
} from './operations/integration-row.generated'
import TableHeader, { TableHeaderCell } from './TableHeader'
import TableRow, { TableRowCell } from './TableRow'
import { Skeleton } from '@material-ui/lab'
import { useCreateIntegrationMutation } from './operations/create-integration.generated'
import { useUpdateIntegrationMutation } from './operations/update-integration.generated'
import { useTestIntegrationMutation } from './operations/test-integration.generated'
import AddEditCustomerIntegration, { AddEditCustomerIntegrationFormFields } from './AddEditCustomerIntegration'
import { useToast } from '../components/Alert/ToastProvider'
import { isTypeName } from '../types/utility'

type CustomerIntegrationsType =
  | IntegrationRow_RefersionIntegration_Fragment
  | IntegrationRow_ImpactIntegration_Fragment
  | IntegrationRow_ImpactSubaffiliateIntegration_Fragment
  | IntegrationRow_RakutenSubaffiliateIntegration_Fragment

const typeNameMap: Record<CustomerIntegrationsType['__typename'], string> = {
  RefersionIntegration: 'Refersion',
  ImpactIntegration: 'Impact',
  ImpactSubaffiliateIntegration: 'ImpactSubaffiliate',
  RakutenSubaffiliateIntegration: 'RakutenSubaffiliate',
}

interface CustomerIntegrationsProps {
  accountId?: string | null
  integrations: CustomerIntegrationsType[]
  isSubaffiliateSection?: boolean
  loading?: boolean
}

type AddEditState =
  | {
      open: true
      editingIntegration?: CustomerIntegrationsType
    }
  | { open: false }

function CustomerIntegrations({
  accountId,
  loading,
  integrations,
  isSubaffiliateSection,
}: CustomerIntegrationsProps): React.ReactElement {
  const [addEditState, setAddEditState] = useState<AddEditState>({ open: false })
  const [addEditError, setAddEditError] = useState<Error | null>(null)
  const [testState, setTestState] = useState<string | null>(null)
  const { showToast } = useToast()
  const [createIntegration] = useCreateIntegrationMutation({
    update(cache, { data }): void {
      const customerIntegration = data?.createIntegration?.integration
      if (!customerIntegration) {
        return
      }
      cache.modify({
        id: cache.identify({ __typename: 'AccountType', id: accountId }),
        fields: {
          integrations(existing = []) {
            const newRef = cache.writeFragment({
              data: customerIntegration,
              fragment: IntegrationRowFragmentDoc,
            })
            return [...existing, newRef]
          },
        },
      })
    },
    onCompleted: () => {
      showToast({
        title: 'Success: Integration added',
        message: 'Integration has been added.',
        severity: 'success',
        autoHideDuration: 5000,
      })
    },
  })
  const [updateIntegration] = useUpdateIntegrationMutation({
    onCompleted: () => {
      showToast({
        title: 'Success: Integration updated',
        message: 'Integration has been updated.',
        severity: 'success',
        autoHideDuration: 5000,
      })
    },
  })
  const [testIntegration, { error: testError, data: testData, loading: testing }] = useTestIntegrationMutation()

  async function handleAddEdit(values: AddEditCustomerIntegrationFormFields): Promise<void> {
    if (!addEditState.open) return

    try {
      if (addEditState.editingIntegration) {
        await updateIntegration({ variables: { ...addEditState.editingIntegration, ...values } })
      } else {
        await createIntegration({ variables: { ...values } })
      }
      handleClose()
      return
    } catch (e) {
      if (e instanceof Error) {
        setAddEditError(e)
      }
    }
  }

  function handleClose() {
    setAddEditError(null)
    setAddEditState({ open: false })
  }

  async function handleTest(id: string) {
    setTestState(id)
    await testIntegration({
      variables: {
        id,
      },
    })
  }

  const renderIntegrationDetails = (integration: CustomerIntegrationsType) => {
    if (integration.__typename === 'RefersionIntegration') {
      return (
        <>
          <TableRowCell>
            {typeNameMap[integration.__typename]} - {integration.name}
          </TableRowCell>
          <TableRowCell minWidth={0} overflow="hidden" textOverflow="ellipsis" whiteSpace="nowrap">
            {integration.key}
          </TableRowCell>
          <TableRowCell />
          <TableRowCell />
        </>
      )
    } else if (integration.__typename === 'ImpactIntegration') {
      return (
        <>
          <TableRowCell>
            {typeNameMap[integration.__typename]} - {integration.name}
          </TableRowCell>
          <TableRowCell minWidth={0} overflow="hidden" textOverflow="ellipsis" whiteSpace="nowrap">
            {integration.accountSid}
          </TableRowCell>
          <TableRowCell minWidth={0} overflow="hidden" textOverflow="ellipsis" whiteSpace="nowrap">
            {integration.campaignId}
          </TableRowCell>
          <TableRowCell minWidth={0} overflow="hidden" textOverflow="ellipsis" whiteSpace="nowrap">
            {integration.campaignRedirectDomain}
          </TableRowCell>
        </>
      )
    } else {
      return (
        <>
          <TableRowCell>
            {typeNameMap[integration.__typename]} - {integration.name}
          </TableRowCell>
          <TableRowCell></TableRowCell>
          <TableRowCell minWidth={0} overflow="hidden" textOverflow="ellipsis" whiteSpace="nowrap">
            {isTypeName(integration, 'RakutenSubaffiliateIntegration')
              ? integration.advertiserId
              : integration.campaignId}
          </TableRowCell>
          <TableRowCell></TableRowCell>
        </>
      )
    }
  }

  return (
    <div>
      {(loading || !!integrations.length) && (
        <TableHeader>
          <TableHeaderCell>Integration</TableHeaderCell>
          <TableHeaderCell>Key / Account SID</TableHeaderCell>
          <TableHeaderCell>Campaign ID</TableHeaderCell>
          <TableHeaderCell>Redirect Domain</TableHeaderCell>
          <TableHeaderCell />
          <TableHeaderCell />
        </TableHeader>
      )}
      {loading && (
        <>
          <TableRow>
            <TableRowCell>
              <Skeleton width={200} />
            </TableRowCell>
            <TableRowCell>
              <Skeleton width={200} />
            </TableRowCell>
            <TableRowCell>
              <Skeleton width={200} />
            </TableRowCell>
            <TableRowCell>
              <Skeleton width={200} />
            </TableRowCell>
            <TableRowCell />
            <TableRowCell />
          </TableRow>
          <TableRow>
            <TableRowCell>
              <Skeleton width={200} />
            </TableRowCell>
            <TableRowCell>
              <Skeleton width={200} />
            </TableRowCell>
            <TableRowCell>
              <Skeleton width={200} />
            </TableRowCell>
            <TableRowCell>
              <Skeleton width={200} />
            </TableRowCell>
            <TableRowCell />
            <TableRowCell />
          </TableRow>
        </>
      )}
      {integrations.map(integration => (
        <TableRow key={integration.id}>
          {renderIntegrationDetails(integration)}
          <TableRowCell textAlign="right">
            <Button
              size="small"
              variant="outlined"
              color="primary"
              onClick={() => setAddEditState({ open: true, editingIntegration: integration })}
            >
              Edit
            </Button>
          </TableRowCell>
          <TableRowCell display="flex" justifyContent="flex-end" alignItems="center">
            <Box mr={2}>
              {testState === integration.id && (
                <>
                  {testing && 'Testing...'}
                  {!testing && (testData?.testIntegration?.ok ? 'Success!' : 'API Key Failed')}
                  {!testing && testError?.message}
                </>
              )}
            </Box>
            <Button
              size="small"
              variant="outlined"
              color="primary"
              onClick={() => handleTest(integration.id)}
              disabled={testing && testState === integration.id}
            >
              Test
            </Button>
          </TableRowCell>
        </TableRow>
      ))}
      <Button size="large" variant="contained" color="primary" onClick={() => setAddEditState({ open: true })}>
        Add Integration
      </Button>
      <AddEditCustomerIntegration
        open={addEditState.open}
        onCancel={handleClose}
        onSubmit={handleAddEdit}
        error={addEditError}
        existing={addEditState.open ? addEditState.editingIntegration : undefined}
        isSubaffiliateSection={isSubaffiliateSection}
      />
    </div>
  )
}

export default CustomerIntegrations
